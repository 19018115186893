exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-to-video-converter-tsx": () => import("./../../../src/pages/article-to-video-converter.tsx" /* webpackChunkName: "component---src-pages-article-to-video-converter-tsx" */),
  "component---src-pages-audio-to-shorts-tsx": () => import("./../../../src/pages/audio-to-shorts.tsx" /* webpackChunkName: "component---src-pages-audio-to-shorts-tsx" */),
  "component---src-pages-automated-youtube-shorts-tsx": () => import("./../../../src/pages/automated-youtube-shorts.tsx" /* webpackChunkName: "component---src-pages-automated-youtube-shorts-tsx" */),
  "component---src-pages-automatic-tiktok-video-maker-tsx": () => import("./../../../src/pages/automatic-tiktok-video-maker.tsx" /* webpackChunkName: "component---src-pages-automatic-tiktok-video-maker-tsx" */),
  "component---src-pages-blog-to-shorts-tsx": () => import("./../../../src/pages/blog-to-shorts.tsx" /* webpackChunkName: "component---src-pages-blog-to-shorts-tsx" */),
  "component---src-pages-create-videos-from-plain-text-in-minutes-tsx": () => import("./../../../src/pages/create-videos-from-plain-text-in-minutes.tsx" /* webpackChunkName: "component---src-pages-create-videos-from-plain-text-in-minutes-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-faceless-instagram-reels-tsx": () => import("./../../../src/pages/faceless-instagram-reels.tsx" /* webpackChunkName: "component---src-pages-faceless-instagram-reels-tsx" */),
  "component---src-pages-faceless-shorts-generator-tsx": () => import("./../../../src/pages/faceless-shorts-generator.tsx" /* webpackChunkName: "component---src-pages-faceless-shorts-generator-tsx" */),
  "component---src-pages-ideas-to-shorts-tsx": () => import("./../../../src/pages/ideas-to-shorts.tsx" /* webpackChunkName: "component---src-pages-ideas-to-shorts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-linkedin-video-maker-tsx": () => import("./../../../src/pages/linkedin-video-maker.tsx" /* webpackChunkName: "component---src-pages-linkedin-video-maker-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project/[...].tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-promote-your-business-on-tiktok-tsx": () => import("./../../../src/pages/promote-your-business-on-tiktok.tsx" /* webpackChunkName: "component---src-pages-promote-your-business-on-tiktok-tsx" */),
  "component---src-pages-promote-your-business-on-youtube-tsx": () => import("./../../../src/pages/promote-your-business-on-youtube.tsx" /* webpackChunkName: "component---src-pages-promote-your-business-on-youtube-tsx" */),
  "component---src-pages-results-page-tsx": () => import("./../../../src/pages/results-page.tsx" /* webpackChunkName: "component---src-pages-results-page-tsx" */),
  "component---src-pages-script-to-shorts-tsx": () => import("./../../../src/pages/script-to-shorts.tsx" /* webpackChunkName: "component---src-pages-script-to-shorts-tsx" */),
  "component---src-pages-social-media-marketing-video-maker-tsx": () => import("./../../../src/pages/social-media-marketing-video-maker.tsx" /* webpackChunkName: "component---src-pages-social-media-marketing-video-maker-tsx" */),
  "component---src-templates-blog-post-list-template-tsx": () => import("./../../../src/templates/blogPostListTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-list-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-communication-post-template-tsx": () => import("./../../../src/templates/communicationPostTemplate.tsx" /* webpackChunkName: "component---src-templates-communication-post-template-tsx" */)
}

